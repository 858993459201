import React from 'react'

function AddCandidate() {
    return (
        <div className="modal-dialog modal-sm modal-right bg-transparent">
        <button type="button" className="btn-close bg-transparent" data-bs-dismiss="modal" aria-label="Close"><i className="dripicons-cross" /></button>   
        <div className="modal-content rounded-0">
          <div className="modal-header border-0 p-0">
            <div className="rightbar-title p-2 border-bottom w-100">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="m-0 text-info">Add Candidate To This Job</h5>
                <div className="ms-auto">
                  <a href="#" className="btn btn-clear py-0 m-1 mt-0 ">Cancel </a>
                  <a href="#" className="btn btn-primary py-1 m-1 mt-0">Add</a>
                </div>
              </div>
            </div> 
          </div>
          <div className="modal-body">
            <div className="rightbar-content h-100">
              <div className="p-3">
                <form action="/" method="post" className="dropzone border" id="myAwesomeDropzone" data-plugin="dropzone" data-previews-container="#file-previews" data-upload-preview-template="#uploadPreviewTemplate">
                  <div className="fallback">
                    <input name="file" type="file" multiple />
                  </div>
                  <div className="dz-message needsclick">
                    <p className="font-22 text-info">Drag and drop resume here</p>
                    <p className="text-muted font-13">
                      or <span className="btn-link">click here to upload resume</span>
                    </p>
                    <span className="text-muted font-13"> Maximum file size: 2 MB</span>
                  </div>
                </form>
                <div className="dropzone-previews mt-3" id="file-previews" />
                <div className="d-none" id="uploadPreviewTemplate">
                  <div className="card mt-1 mb-0 shadow-none border">
                    <div className="p-2">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <img data-dz-thumbnail src="#" className="avatar-sm rounded bg-light" alt />
                        </div>
                        <div className="col ps-0">
                          <a href="javascript:void(0);" className="text-muted fw-bold" data-dz-name />
                          <p className="mb-0" data-dz-size />
                        </div>
                        <div className="col-auto">
                          {/* Button */}
                          <a href className="btn btn-link btn-lg text-muted" data-dz-remove>
                            <i className="dripicons-cross" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="field mb-3 form-group required">
                      <input className="form-control" type="text" name="First-name" id="First-name" placeholder="First Name" required />
                      <label htmlFor="First-name" className="control-label">First Name</label>
                    </div>
                  </div>
                  <div className=" col-md-4">
                    <div className="field mb-3">
                      <input className="form-control" type="text" name="middle-name" id="middle-name" placeholder="Middle Name" />
                      <label htmlFor="middle-name">Middle Name</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="field mb-3 ">
                      <input className="form-control" type="text" name="last-name" id="last-name" placeholder="Last Name" required />
                      <label htmlFor="last-name"> Last Name </label>
                    </div>
                  </div>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="email" name="mail" id="mail" placeholder="Email" required />
                  <label htmlFor="mail">Email</label>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="text" name="mobile" id="mobile" placeholder="Mobile" />
                  <label htmlFor="mobile"> Mobile </label>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="date" name="date" id="date" placeholder="Date of Birth" />
                  <label htmlFor="date"> Date of Birth</label>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="text" name="texp" id="texp" placeholder="Total Experience (in Years)" required />
                  <label htmlFor="texp">Total Experience (in Years)</label>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="text" name="exp" id="exp" placeholder="Relevant Experience (in Years)" required />
                  <label htmlFor="exp">Relevant Experience (in Years)</label>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="text" name="cursal" id="cursal" placeholder="Current Salary" required />
                  <label htmlFor="cursal">Current Salary</label>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="text" name="expsal" id="expsal" placeholder="Expected Salary" required />
                  <label htmlFor="expsal">Expected Salary</label>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="text" name="notice" id="notice" placeholder="Notice Period" required />
                  <label htmlFor="notice"> Notice Period </label>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="text" name="job-change" id="job-change" placeholder="Reason for job change" required />
                  <label htmlFor="job-change"> Reason for job change </label>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="text" name="addrs" id="addrs" placeholder="Permanent Address" required />
                  <label htmlFor="addrs"> Permanent Address </label>
                </div>
                <div className="field mb-3">
                  <input className="form-control" type="text" name="loc" id="loc" placeholder="Current Location" required />
                  <label htmlFor="loc"> Current Location </label>
                </div>
                <div className="field d-inline mt-2">
                  <h5> Are you comfortable with Chandigarh/ Mohali Location </h5>
                  <div className="form-check form-check-inline position-relative">
                    <input type="radio" id="customRadio3" name="customRadio1" className="form-check-input" />
                    <label className="form-check-label" htmlFor="customRadio3">Yes</label>
                  </div>
                  <div className="form-check form-check-inline position-relative ms-5">
                    <input type="radio" id="customRadio4" name="customRadio1" className="form-check-input" />
                    <label className="form-check-label" htmlFor="customRadio4">No</label>
                  </div>
                </div>
                <div className="field d-inline mt-2 mb-3">
                  <h5> Are you open for work from office operations </h5>
                  <div className="form-check form-check-inline position-relative">
                    <input type="radio" id="customRadio5" name="customRadio2" className="form-check-input" />
                    <label className="form-check-label" htmlFor="customRadio5">Yes</label>
                  </div>
                  <div className="form-check form-check-inline position-relative ms-5">
                    <input type="radio" id="customRadio6" name="customRadio2" className="form-check-input" />
                    <label className="form-check-label" htmlFor="customRadio6">No</label>
                  </div>
                </div>
                <div className="mt-2 mb-3">
                  <label htmlFor> Provide us with links to see some of your work (Git/ Dribble/ Behance/ Pinterest/ Blog/ Medium) </label>
                  <input className="form-control d-inline mt-2 w-75" type="text" name="work" id="work" placeholder="Hyperlink" />
                  <button className="btn">
                    <i className="mdi mdi-plus mdi-24px px-2" />
                  </button>
                </div>
                <div className="mt-2 mb-3">
                  <form className="border px-3">
                    <h4> Employer </h4>
                    <div className="mb-3 field">
                      <input type="text" name="desig" id="desig" className="form-control" placeholder="Designation" required />
                      <label htmlFor="desig"> Designation </label>
                    </div>
                    <div className="mb-3 field">
                      <input type="text" name="comname" id="comname" className="form-control" placeholder="Company/Business Name" required />
                      <label htmlFor="comname"> Company/Business Name </label>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4 pe-0 position-relative" id="datepicker1">
                        <input type="text " className="form-control " data-provide="datepicker " data-date-container="#datepicker1 " placeholder="From " required />
                      </div>
                      <div className="col-md-4 pe-0 position-relative" id="datepicker2">
                        <input type="text " className="form-control " data-provide="datepicker " data-date-container="#datepicker2 " placeholder="To " required />
                      </div>
                      <div className="col-md-4 pe-0 position-relative">
                        <div className="form-check form-switch">
                          <input type="checkbox" className="form-check-input" id="customSwitch1" />
                          <label className="form-check-label mx-0 px-0 font-11" htmlFor="customSwitch1">Currently works here</label>
                        </div>
                      </div>
                      <div className="mt-2 mb-3 field">
                        <textarea rows={3} id="text-area" type="text" name="text-area" className="form-control" placeholder="Summary" defaultValue={""} />
                        <label htmlFor="text-area"> Summary </label>
                      </div>
                      <div className="field my-3">
                        <a href="#" className="text-link"><i className="mdi mdi-plus-circle mdi-18px" /> Add Employer </a>
                      </div>
                    </div></form>
                  <div className="mt-2 mb-3">
                    <form className="border px-3">
                      <h4> Education </h4>
                      <div className="mb-3 field">
                        <input type="text" name="degree" id="degree" className="form-control" placeholder="Degree" required />
                        <label htmlFor="degree"> Degree </label>
                      </div>
                      <div className="mb-3 field">
                        <input type="text" name="school" id="school" className="form-control" placeholder="Institution/School Name" required />
                        <label htmlFor="school"> Institution/School Name </label>
                      </div>
                      <div className="mb-3 field">
                        <input type="text" name="study-field" id="study-field" className="form-control" placeholder="Field of Study/Major " />
                        <label htmlFor="study-field"> Field of Study/Major </label>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-3 mb-3 pe-0 field">
                          <input type="text" name="grade" id="grade" className="form-control" placeholder="Grade " />
                          <label htmlFor="grade"> Grade </label>
                        </div>
                        <div className="col-md-3 pe-0 position-relative" id="datepicker1">
                          <input type="text " className="form-control " data-provide="datepicker " data-date-container="#datepicker1 " placeholder="From " required />
                        </div>
                        <div className="col-md-3 pe-0 position-relative" id="datepicker2">
                          <input type="text " className="form-control " data-provide="datepicker " data-date-container="#datepicker2 " placeholder="End " required />
                        </div>
                        <div className="col-md-3 pe-0 position-relative">
                          <div className="form-check form-switch">
                            <input type="checkbox" className="form-check-input" id="customSwitch1" />
                            <label className="form-check-label mx-0 px-0 font-11" htmlFor="customSwitch1">Currently pursuing</label>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="field my-3">
                      <a href="#" className="text-link"><i className="mdi mdi-plus-circle mdi-18px" /> Add Education</a>
                    </div>
                  </div>
                  <div className="field d-inline mt-2 mb-3">
                    <h5> Do you have 1+ years of experience as a Dot Net Developer? </h5>
                    <div className="form-check form-check-inline position-relative">
                      <input type="radio" id="customRadio7" name="customRadio3" className="form-check-input" />
                      <label className="form-check-label" htmlFor="customRadio7">Yes</label>
                    </div>
                    <div className="form-check form-check-inline position-relative ms-5">
                      <input type="radio" id="customRadio8" name="customRadio3" className="form-check-input" />
                      <label className="form-check-label" htmlFor="customRadio8">No</label>
                    </div>
                  </div>
                  <div className="field d-inline mt-2 mb-3">
                    <h5> Do you have hand on experience on MVC </h5>
                    <div className="form-check form-check-inline position-relative">
                      <input type="radio" id="customRadio9" name="customRadio4" className="form-check-input" />
                      <label className="form-check-label" htmlFor="customRadio9">Yes</label>
                    </div>
                    <div className="form-check form-check-inline position-relative ms-5">
                      <input type="radio" id="customRadio10" name="customRadio4" className="form-check-input" />
                      <label className="form-check-label" htmlFor="customRadio10">No</label>
                    </div>
                  </div>
                  <div className="field d-inline mt-2 mb-3">
                    <h5> Do you have working experience wit GIT </h5>
                    <div className="form-check form-check-inline position-relative">
                      <input type="radio" id="customRadio11" name="customRadio5" className="form-check-input" />
                      <label className="form-check-label" htmlFor="customRadio11">Yes</label>
                    </div>
                    <div className="form-check form-check-inline position-relative ms-5">
                      <input type="radio" id="customRadio12" name="customRadio5" className="form-check-input" />
                      <label className="form-check-label" htmlFor="customRadio12">No</label>
                    </div>
                  </div>
                  <div className="field d-inline mt-2 mb-3">
                    <h5> Can you work independently? </h5>
                    <div className="form-check form-check-inline position-relative">
                      <input type="radio" id="customRadio13" name="customRadio6" className="form-check-input" />
                      <label className="form-check-label" htmlFor="customRadio13">Yes</label>
                    </div>
                    <div className="form-check form-check-inline position-relative ms-5">
                      <input type="radio" id="customRadio14" name="customRadio6" className="form-check-input" />
                      <label className="form-check-label" htmlFor="customRadio14">Partially</label>
                    </div>
                    <div className="form-check form-check-inline position-relative ms-5">
                      <input type="radio" id="customRadio15" name="customRadio6" className="form-check-input" />
                      <label className="form-check-label" htmlFor="customRadio15">No</label>
                    </div>
                  </div>
                  <div className="field mt-3 mb-3">
                    <input className="form-control" type="text" name="expert" id="expert" placeholder="Technical Skills - Expert Level" required />
                    <label htmlFor="expert"> Technical Skills - Expert Level </label>
                  </div>
                  <div className="field mt-2 mb-3">
                    <input className="form-control" type="text" name="intermediate" id="intermediate" placeholder="Technical Skills - Intermediate Level" required />
                    <label htmlFor="intermediate"> Technical Skills - Intermediate Level </label>
                  </div>
                  <div className="field mt-2 mb-3">
                    <input className="form-control" type="text" name="beginner" id="beginner" placeholder="Technical Skills - Beginner Level" required />
                    <label htmlFor="beginner"> Technical Skills - Beginner Level </label>
                  </div>
                  <div className="row mt-3 mb-3">
                    <div className="col-md-6 pe-0 dropdown">
                      <input className="form-control dropdown-toggle " type="text" name="dropdownMenuButton" placeholder="Source" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" required />
                      <label className="w-50" htmlFor="dropdownMenuButton"> Source </label>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div className="p-2">
                          <input className="form-control" aria-controls placeholder="Search" type="search" />
                        </div>
                        <div className="p-2 overflow-scroll fix-dropdown">
                          <ul>
                            <li>
                              <a className href="#"> Adzuna </a>
                            </li>
                            <li>
                              <a className href="#"> Direct </a>
                            </li>
                            <li>
                              <a className href="#"> Facebook </a>
                            </li>
                            <li>
                              <a className href="#"> FB page tab </a>
                            </li>
                            <li>
                              <a className href="#"> Glassdoor </a>
                            </li>
                            <li>
                              <a className href="#"> Indeed </a>
                            </li>
                            <li>
                              <a className href="#"> Internal </a>
                            </li>
                            <li>
                              <a className href="#"> Linkedin </a>
                            </li>
                            <li>
                              <a className href="#"> Monster </a>
                            </li>
                            <li>
                              <a className href="#"> Naukri </a>
                            </li>
                            <li>
                              <a className href="#"> Neuvoo </a>
                            </li>
                            <li>
                              <a className href="#"> Twitter </a>
                            </li>
                            <li>
                              <a className href="#"> ZipRecruiter </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 dropdown">
                      <input className="form-control dropdown-toggle " type="text" name="dropdownMenuButton1" placeholder="Source Category" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" required />
                      <label className="w-50" htmlFor="dropdownMenuButton1"> Source Category </label>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <div className="p-2">
                          <input className="form-control" aria-controls placeholder="Search" type="search" />
                        </div>
                        <div className="p-2 overflow-scroll fix-dropdown">
                          <ul>
                            <li>
                              <a className href="#"> Advertisement </a>
                            </li>
                            <li>
                              <a className href="#"> Agencies </a>
                            </li>
                            <li>
                              <a className href="#"> Email </a>
                            </li>
                            <li>
                              <a className href="#"> Events </a>
                            </li>
                            <li>
                              <a className href="#"> Free Job Boards </a>
                            </li>
                            <li>
                              <a className href="#"> Job Boards </a>
                            </li>
                            <li>
                              <a className href="#"> Portal </a>
                            </li>
                            <li>
                              <a className href="#"> Premium Job Boards </a>
                            </li>
                            <li>
                              <a className href="#"> Recruitment Drive </a>
                            </li>
                            <li>
                              <a className href="#"> Referral </a>
                            </li>
                            <li>
                              <a className href="#"> Referral - social </a>
                            </li>
                            <li>
                              <a className href="#"> Social </a>
                            </li>
                            <li>
                              <a className href="#"> Universities </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field dropdown">
                    <input className="form-control dropdown-toggle " type="text" name="dropdownMenuButton2" placeholder="Searh/Add New" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                    <label className="w-50" htmlFor="dropdownMenuButton2"> Tags </label>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <div className="p-2 overflow-scroll fix-dropdown">
                        <ul>
                          <li>
                            <a className href="#"> Aptitute score is low </a>
                          </li>
                          <li>
                            <a className href="#"> Shortlisted </a>
                          </li>
                          <li>
                            <a className href="#"> Not open for work from home </a>
                          </li>
                          <li>
                            <a className href="#"> Skills not matching </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="field position-relative">
                    <div className="form-check form-switch">
                      <input type="checkbox" className="form-check-input" id="customSwitch1" />
                      <label className="form-check-label mx-0 px-0 font-14" htmlFor="customSwitch1">Send an acknowledgment email to candidate</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default AddCandidate
