import React from 'react'

function JobBoards() {
    return (
       <>
         <div className="row m-0 px-2">
          <div className="col-md-12 my-2">
            <div className="p-3 bg-white rounded">
              <h4> Job Boards </h4>
              <p> Sponsored </p>
              <div className="d-flex border p-2 rounded hov-eff align-items-center">
                <div className>
                  <svg className="my-2" id="Indeed" viewBox="0 0 100 20" style={{width: 90}}>
                    <path style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#2264F3'}} d="M72.6,3.5C72.4,3.2,72,3,71.6,3c-0.5,0-0.8,0.2-1.1,0.5c-0.2,0.3-0.4,0.8-0.4,1.4v4.5
                              c-0.6-0.6-1.2-1.1-1.8-1.4C68,7.9,67.5,7.7,67,7.6c-0.3,0-0.6-0.1-0.9-0.1c-1.5,0-2.7,0.5-3.7,1.6c-0.9,1.1-1.4,2.6-1.4,4.5
                              c0,0.9,0.1,1.7,0.4,2.5c0.2,0.8,0.6,1.4,1,2c0.5,0.6,1,1,1.6,1.3c0.6,0.3,1.3,0.5,2,0.5c0.3,0,0.7,0,1-0.1c0.2,0,0.4-0.1,0.6-0.1
                              c0.5-0.2,0.9-0.4,1.3-0.7c0.4-0.3,0.8-0.7,1.2-1.2v0.3c0,0.6,0.1,1,0.4,1.3c0.3,0.3,0.6,0.5,1,0.5c0.4,0,0.8-0.1,1-0.4
                              c0.3-0.3,0.4-0.7,0.4-1.3V4.8C73,4.2,72.9,3.8,72.6,3.5 M69.5,15.9c-0.3,0.6-0.6,1-1.1,1.3c-0.4,0.3-0.9,0.4-1.5,0.4h0
                              c-0.5,0-1-0.1-1.5-0.4c-0.4-0.3-0.8-0.7-1.1-1.3c-0.3-0.6-0.4-1.3-0.4-2.1c0-0.8,0.1-1.5,0.4-2.1c0.2-0.6,0.6-1.1,1-1.4
                              c0.4-0.3,0.9-0.5,1.5-0.5h0c0.5,0,1,0.2,1.5,0.5c0.4,0.3,0.8,0.8,1.1,1.3c0.3,0.6,0.4,1.3,0.4,2.1C69.9,14.6,69.8,15.4,69.5,15.9" />
                    <path style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#2264F3'}} d="M60.1,16.1c-0.2-0.2-0.4-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.7,0.2c-0.4,0.4-0.7,0.6-0.9,0.9
                              c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.1-1,0.1c-0.1,0-0.2,0-0.2,0c-0.5,0-0.9-0.2-1.3-0.4
                              c-0.5-0.3-0.8-0.7-1.1-1.2c-0.3-0.5-0.4-1.2-0.4-1.8h6c0.8,0,1.4-0.1,1.9-0.3c0.4-0.2,0.7-0.7,0.7-1.5c0-0.8-0.2-1.6-0.6-2.4
                              c-0.4-0.8-1.1-1.4-1.9-2c-0.9-0.5-1.9-0.8-3.1-0.8h-0.1c-0.9,0-1.7,0.2-2.4,0.4c-0.8,0.3-1.4,0.7-1.9,1.3c-0.5,0.6-0.9,1.2-1.2,2
                              c-0.3,0.8-0.4,1.6-0.4,2.5c0,1.9,0.5,3.4,1.6,4.6c1,1.1,2.5,1.6,4.3,1.7c0.1,0,0.2,0,0.3,0c0.9,0,1.6-0.1,2.3-0.3
                              c0.7-0.2,1.2-0.5,1.6-0.8c0.4-0.3,0.8-0.7,1-1.1c0.2-0.4,0.3-0.7,0.3-1C60.4,16.5,60.3,16.2,60.1,16.1 M52.9,10.5
                              c0.5-0.5,1.1-0.8,1.9-0.8h0c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.3,0.8,2.4H52C52.1,11.8,52.4,11,52.9,10.5" />
                    <path style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#2264F3'}} d="M47.1,15.8c-0.3,0-0.5,0.1-0.7,0.2c-0.4,0.4-0.7,0.6-0.9,0.9c-0.2,0.2-0.5,0.4-0.8,0.6
                              c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.1-1,0.1c-0.1,0-0.2,0-0.2,0c-0.5,0-0.9-0.2-1.3-0.4c-0.4-0.3-0.8-0.7-1.1-1.2
                              c-0.3-0.5-0.4-1.2-0.4-1.8h6c0.8,0,1.4-0.1,1.9-0.3c0.4-0.2,0.7-0.7,0.7-1.5c0-0.8-0.2-1.6-0.6-2.4c-0.4-0.8-1.1-1.4-1.9-2
                              c-0.9-0.5-1.9-0.8-3.1-0.8h-0.1c-0.9,0-1.7,0.2-2.4,0.4c-0.8,0.3-1.4,0.7-1.9,1.3c-0.5,0.6-0.9,1.2-1.2,2c-0.3,0.8-0.4,1.6-0.4,2.5
                              c0,1.9,0.6,3.4,1.6,4.6c1,1.1,2.4,1.6,4.3,1.7c0.1,0,0.2,0,0.3,0c0.9,0,1.6-0.1,2.3-0.3c0.7-0.2,1.2-0.5,1.6-0.8
                              c0.4-0.3,0.8-0.7,1-1.1c0.2-0.4,0.3-0.7,0.3-1c0-0.3-0.1-0.6-0.3-0.7C47.6,15.9,47.4,15.8,47.1,15.8 M40.7,10.5
                              c0.5-0.5,1.1-0.8,1.9-0.8h0c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.3,0.9,2.4h-5.6C39.9,11.8,40.2,11,40.7,10.5" />
                    <path style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#2264F3'}} d="M6.4,18v-7.3c0.2,0,0.4,0,0.6,0c1,0,1.9-0.3,2.7-0.7v8c0,0.7-0.2,1.2-0.5,1.5C8.9,19.8,8.5,20,8,20
                              c-0.5,0-0.9-0.2-1.2-0.5C6.5,19.1,6.4,18.6,6.4,18" />
                    <path style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#2264F3'}} d="M35.2,3.5C34.9,3.2,34.6,3,34.1,3c-0.5,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.4,0.8-0.4,1.4v4.5
                              c-0.6-0.6-1.2-1.1-1.8-1.4c-0.4-0.2-0.8-0.3-1.3-0.4c-0.3,0-0.6-0.1-0.9-0.1c-1.5,0-2.8,0.5-3.7,1.6c-0.9,1.1-1.4,2.6-1.4,4.5
                              c0,0.9,0.1,1.7,0.3,2.5c0.2,0.8,0.6,1.4,1,2c0.5,0.6,1,1,1.6,1.3c0.6,0.3,1.3,0.5,2,0.5c0.3,0,0.6,0,1-0.1c0.2,0,0.4-0.1,0.6-0.1
                              c0.5-0.2,0.9-0.4,1.3-0.7c0.4-0.3,0.8-0.7,1.2-1.2v0.3c0,0.6,0.1,1,0.4,1.3c0.3,0.3,0.6,0.5,1,0.5c0.4,0,0.8-0.1,1-0.4
                              c0.3-0.3,0.4-0.7,0.4-1.3V4.8C35.6,4.2,35.4,3.8,35.2,3.5 M32.1,15.9c-0.3,0.6-0.6,1-1.1,1.3c-0.4,0.3-0.9,0.4-1.5,0.4h0
                              c-0.5,0-1-0.1-1.5-0.4c-0.5-0.3-0.8-0.7-1.1-1.3c-0.3-0.6-0.4-1.3-0.4-2.1c0-0.8,0.1-1.5,0.4-2.1c0.2-0.6,0.6-1.1,1-1.4
                              c0.4-0.3,0.9-0.5,1.5-0.5h0c0.5,0,1,0.2,1.4,0.5c0.5,0.3,0.8,0.8,1.1,1.3c0.3,0.6,0.4,1.3,0.4,2.1C32.5,14.6,32.4,15.4,32.1,15.9" />
                    <path style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#2264F3'}} d="M14.6,9.2v0.4c0.5-0.7,1.1-1.2,1.8-1.6c0.6-0.3,1.4-0.5,2.2-0.5c0.8,0,1.5,0.2,2.2,0.5
                              c0.6,0.3,1.1,0.8,1.4,1.5c0.2,0.4,0.3,0.8,0.4,1.2c0.1,0.4,0.1,1,0.1,1.7v5.7c0,0.6-0.1,1.1-0.4,1.4c-0.3,0.3-0.6,0.5-1.1,0.5
                              c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.8-0.4-1.4V13c0-1-0.1-1.8-0.4-2.3c-0.3-0.5-0.8-0.8-1.7-0.8c-0.5,0-1,0.2-1.5,0.5
                              c-0.4,0.3-0.8,0.8-1,1.3c-0.1,0.5-0.2,1.3-0.2,2.6v3.8c0,0.6-0.1,1.1-0.4,1.4c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.8-0.2-1.1-0.5
                              c-0.3-0.3-0.4-0.8-0.4-1.4V9.3c0-0.6,0.1-1,0.4-1.3c0.2-0.3,0.6-0.4,1-0.4c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.6
                              C14.5,8.6,14.6,8.9,14.6,9.2" />
                    <path style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#2264F3'}} d="M6.4,0.5c2.1-0.7,4.4-0.7,6.2,0.8c0.3,0.3,0.7,0.7,0.9,1.2c0.2,0.6-0.6-0.1-0.7-0.1c-0.6-0.4-1.2-0.7-1.8-0.9
                              C7.4,0.3,4.1,2.3,2.1,5.3C1.2,6.6,0.7,8,0.2,9.5c0,0.2-0.1,0.4-0.2,0.5C0,10.2,0,9.6,0,9.6C0.1,9,0.2,8.3,0.4,7.7
                              C1.3,4.4,3.4,1.7,6.4,0.5" />
                    <path style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#2264F3'}} d="M9.1,8.6C7.8,9.2,6.3,8.7,5.7,7.5C5.1,6.3,5.6,4.7,6.8,4.1C8,3.5,9.6,4,10.2,5.2C10.8,6.5,10.3,8,9.1,8.6" />
                  </svg>
                  <br />
                  <span className="col-md-12 text-muted"> Sponsored Jobs on Indeed get up to 3X more applications. Add a budget to sponsor this job</span>
                </div>
                <div className="position-relative ms-auto">
                  <button type="button" className="btn-sm border bg-white py-1 px-2">  
                    <span className="small font-13">View in Indeed<i className="mdi mdi-share" /> </span> 
                  </button>
                  <button type="button " className="btn-sm border bg-white py-1 px-2" data-bs-toggle="modal" data-bs-target="#standard-modal">
                    <span className="small font-13 "> Add Budget </span>
                  </button>
                  <i className="btn mdi mdi-dots-vertical dropdown-toggle btn-no-image toogle-btn" id="dropdownMenuButton5" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                  <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton5">
                    <a className="dropdown-item" href="#">
                      <div className="fw-semibold"> Pause Job Posting </div>
                      <p className="mb-0 font-12"> Temporarily hide the job posting from Indeed listing </p>
                    </a>
                    <a className="dropdown-item" href="#">
                      <div className="fw-semibold"> Unpublish </div>
                      <p className="mb-0 font-12"> Unpublish this job from Indeed </p>
                    </a>
                    <a className="dropdown-item" href="#">
                      <div className="fw-semibold"> Re-publish </div>
                      <p className="mb-0 font-12"> Close current job and publish as a new job </p>
                    </a>
                  </div>
                </div>
              </div>
              <div id="standard-modal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="standard-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header p-3">
                      <h4 className="modal-title" id="standard-modalLabel">Sponsor job on Indeed</h4>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" />
                    </div>
                    <div className="modal-body p-3">
                      <p className="text-normal">Choose type of budget</p>
                      <div className="my-2">
                        <div className="border rounded btn-group px-0 mb-2">
                          <button type="button" className="border-end btn btn-white px-2 tab-selection">One-time</button>
                          {/* <hr width="1" size="8"> */}
                          <button type="button" className="border-end btn btn-white px-2">Daily</button>
                          {/* <hr width="1" size="8"> */}
                          <button type="button" className="btn btn-white px-2">Monthly</button>
                        </div>
                      </div>
                      <p className="text-normal mb-1">Set a budget to sponsor this job on Indeed</p>
                      <p className="text-muted mb-3">
                        <i className="dripicons-information" /> Recommeded budget (400$) based on your job title and location
                      </p>
                      <div className="position-relative">
                        <input type="text" className="form-control border-top-0 border-start-0 border-end-0 rounded-0 job-hiring h3 fw-light ps-5" placeholder="Enter Value here" />
                        <span className="currency left-icon job-hiring h3 fw-light position-absolute">INR</span>
                      </div>
                      <div className="row">
                        <div className="col-md-6 my-2">
                          <input type="text" className="form-control date" id="birthdatepicker" data-toggle="date-picker" data-single-date-picker="true" />
                        </div>
                      </div>
                    </div>
                    {/* modal-body */}
                    <div className="modal-footer">
                      <button type="button" className="btn btn-light" data-bs-dismiss="modal"> Cancel </button>
                      <button type="button" className="btn btn-primary"> Add Budget </button>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {/* /.modal */}
              <div className="col-md-2 lh-base py-2 my-3 text-center rounded border ">
                <svg id="free-job-board " viewBox="0 0 60 60 " className="d-sm-flex w-25 m-auto ">
                  <path style={{fill: '#979797'}} d="M48.5,20.7l5.3-1.7c0.5-0.2,1.1,0.1,1.3,0.7c0.2,0.5-0.1,1.1-0.7,1.3l-5.3,1.7c-0.1,0-0.2,0-0.3,0 c-0.4,0-0.8-0.3-1-0.7C47.6,21.5,47.9,20.9,48.5,20.7z M48.9,9.8c-0.4-0.3-1.1-0.3-1.4,0.2l-3,3.8c-0.3,0.4-0.3,1.1,0.2,1.4
                           c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4l3-3.8C49.4,10.8,49.3,10.2,48.9,9.8z M54.1,30.3L48.9,29c-0.5-0.1-1.1,0.2-1.2,0.7 c-0.1,0.5,0.2,1.1,0.7,1.2l5.3,1.3c0.1,0,0.2,0,0.2,0c0.4,0,0.9-0.3,1-0.8C55,31,54.7,30.4,54.1,30.3z
                           M41.9,19.4 c-1-0.6-2.2-0.8-3.4-0.6l0.5,1.9c0.6,0,1.3,0.1,1.8,0.4c0.8,0.4,1.3,1.1,1.5,2c0.4,1.5-0.3,3.1-1.7,3.8l0.5,2c2.4-1,3.8-3.6,3.1-6.2 C44,21.2,43.1,20.1,41.9,19.4z " />
                  <path style={{fill: '#979797'}} d="M18.4,47.3l-0.7-0.6c-0.1-0.1-0.2-0.2-0.3-0.4L15,37.1c3.7,0.3,7.5,1,11.1,1.9v-2 c-3.6-0.9-7.3-1.6-11-1.9h-0.6l-2.2-8.3l0.8-0.6c5.4-3.8,10.3-8.4,14.4-13.6l0.7-0.8l6.4,23.9h2l-6.8-25.5c0.2-0.1,0.4-0.2,0.7-0.2
                           L32,9.7c0.3-0.1,0.6,0,0.8,0.1c0.2,0.1,0.4,0.4,0.5,0.6l6.8,25.4h2L35.2,9.9C35,9.1,34.4,8.4,33.7,8s-1.5-0.5-2.3-0.3l-1.5,0.4 c-1.3,0.3-2.4,1.1-3.2,2.1l-0.8,1.1c-4,5.1-8.6,9.5-13.9,13.2l-1.3,0.9l-3.3,0.9c-0.9,0.2-1.6,0.8-2,1.5c-0.4,0.8-0.6,1.7-0.3,2.5
                           l1.4,5.2c0.3,1.1,1.1,1.9,2.1,2.3L11.3,48c0.2,0.6,0.5,1.1,0.9,1.4l2.7,2.2c0.5,0.4,1.1,0.6,1.8,0.6c0.2,0,0.5,0,0.7-0.1 c1-0.3,1.8-1.1,2-2.1C19.6,49,19.3,47.9,18.4,47.3z M8.4,35.1L7,29.9c-0.1-0.3,0-0.7,0.1-1c0.2-0.3,0.5-0.5,0.8-0.6l2.5-0.7l2.1,7.7
                           L10,36C9.3,36.2,8.6,35.8,8.4,35.1z M16.9,50.3c-0.2,0.1-0.5,0-0.7-0.2l-2.7-2.2c-0.1-0.1-0.2-0.2-0.3-0.4l-2.6-9.6l2.3-0.6l2.6,9.6 c0.2,0.5,0.5,1,1,1.4l0.7,0.6c0.3,0.3,0.3,0.6,0.3,0.8C17.5,49.8,17.4,50.2,16.9,50.3z " />
                  <path style={{fill: '#FF5969'}} d="M57.4,38h-28c-0.5,0-1,0.4-1,1v12.7c0,0.5,0.4,1,1,1h28c0.5,0,1-0.4,1-1V39 C58.3,38.4,57.9,38,57.4,38z M56.6,50.9H30.1V39.8h26.5V50.9z M34.7,48.3h-1.5v-7h4.6v1.3h-3.1v1.8h2.9v1.2h-2.9V48.3z
                           M40.5,48.3 H39v-5.4h1.4v0.9h0.1c0.1-0.5,0.7-0.9,1.4-0.9c0.2,0,0.4,0,0.6,0.1v1.3c-0.1,0-0.4-0.1-0.7-0.1c-0.8,0-1.3,0.5-1.3,1.2V48.3z M45.6,48.5c1.3,0,2.2-0.6,2.5-1.7h-1.3c-0.2,0.4-0.5,0.6-1.1,0.6c-0.7,0-1.2-0.5-1.2-1.3V46h3.7v-0.4c0-1.7-0.9-2.7-2.5-2.7
                           c-1.6,0-2.6,1.1-2.6,2.8C43,47.4,44,48.5,45.6,48.5z M45.6,43.9c0.7,0,1.1,0.4,1.1,1.2h-2.3C44.5,44.4,44.9,43.9,45.6,43.9z M51.6,48.5c1.3,0,2.2-0.6,2.5-1.7h-1.3c-0.2,0.4-0.5,0.6-1.1,0.6c-0.7,0-1.2-0.5-1.2-1.3V46h3.7v-0.4c0-1.7-0.9-2.7-2.5-2.7
                           c-1.6,0-2.6,1.1-2.6,2.8C49,47.4,50,48.5,51.6,48.5z M51.6,43.9c0.7,0,1.1,0.4,1.1,1.2h-2.3C50.5,44.4,50.9,43.9,51.6,43.9z " />
                </svg>
                <span> Free Job Boards </span>
              </div>
            </div>
          </div>
        </div>
       </>
    )
}

export default JobBoards
