import React, { useState } from 'react'
import {Link, withRouter} from 'react-router-dom'
import Rating from './candidates/Rating';
import Stages from './candidates/Stages';
import makeAnimated from 'react-select/animated';
import Select from 'react-select'
function CandidateTable(props) {

  const [candidate,setCandidate]=useState(props.candidate);
  const owner=props.owner
   console.log(props.candidate)
   
    const animatedComponents = makeAnimated();

    const options = [
      { value: 'Priyanka', label: 'Priyanka' },
      { value: 'Gaurav', label: 'Gaurav' },
      { value: 'vanilla', label: 'Vanilla' }
    ]

    const handleArchive=(id)=>{
      console.log(id)
      let c=candidate.slice(0)
      let cand=c.filter(u=>u.id===id);
      cand.archived=true;
     setCandidate(cand)
     console.log('final',candidate)
      //setId(id)
    }

const handleDelete=(id)=>{
  console.log(id)
}

const handleClick=(id)=>{
  console.log(id)
}

    return (
        <>
             <div className="table-responsive table-custom px-3 mt-n1">
                <table id="scroll-vertical-datatable" className="table table-condensed">
                  <thead>
                    <tr>
                      <th className="bg-secondary align-middle pe-0"><input type="checkbox" className="form-check-input" id="customCheck1" /></th>
                      <th className="bg-secondary text-white align-middle">Candidates</th>
                      <th className="bg-secondary text-white align-middle">Applied For</th>
                      <th className="bg-secondary text-white align-middle">Stages</th>
                      <th className="bg-secondary text-white align-middle">Owner</th>
                      <th className="bg-secondary text-white align-middle" colSpan={3}>Applied Date</th>
                    </tr>
                  </thead>
                  <tbody>

                      {candidate.map((u,i)=>
                      
                    <tr key={i} className="candidates-row" >
                      <td className="td-border-left position-relative align-middle pe-0"><input type="checkbox" className="form-check-input" id="customCheck1" /></td>
                      <td className="align-middle" onClick={()=>handleClick(u.id)}>
                        <div className="position-relative candidate-info" >
                        <div className="text-primary fw-bold col-xl-11 d-block" >{u.name}</div>
                          <div className="text-truncate w-75">{u.company_name}</div>
                          <div className="display-information bg-white shadow">
                            <div className="arrow" />
                            <div className="p-3">
                              <div className="fw-bold text-truncate">{u.name}</div>
                              <p className="text-muted">
                                Senior Dotnet and Xamarin Developer  &nbsp; | &nbsp;
                                COGNITER TECHNOLOGIES
                              </p>
                              <div className="btn-full-width text-truncate">
                                <i className="dripicons-mail align-middle" /> &nbsp;Riteshsanan05@gmail.com
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <div className="text-info font-600">{u.JobTitle}</div>
                        <div>{u.JobName}</div>
                      </td>
                      <td className="align-middle">
                        <Stages/>
                        <ul className="stages screening">
                          <li className="active">
                            <span aria-label="Sourcing" className="hint--bottom">1</span>
                          </li>
                          <li>
                            <span aria-label="Screening" className="hint--bottom">2</span>
                          </li>
                          <li>
                            <span aria-label="Interviews" className="hint--bottom">3</span>
                          </li>
                          <li>
                            <span aria-label="Offer" className="hint--bottom">4</span>
                          </li>
                          <li>
                            <span aria-label="Hire" className="hint--bottom">5</span>
                          </li>
                        </ul>
                      </td>
                      <td className="align-middle">
                        {/* <a href="#" className="users-select position-relative p-1 d-block" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="mdi mdi-plus-circle-outline mdi-24px d-inline-block align-middle  me-1" /><span className="d-inline-block align-middle"> Add owner</span>
                        </a> */}
                        {/* <div className="dropdown-menu p-0" aria-labelledby="dropdownMenuButton1"> */}
                          {/* <div className="p-2">
                            <input className="ember-power-select-search-input form-control" aria-controls="ember-power-select-options-ember2026" placeholder="Search" type="search" />
                          </div> */}
                          {/* <ul> */}
                          <Select         
                            //closeMenuOnSelect={true}
                           // components={animatedComponents}
                          //defaultValue={[colourOptions[4], colourOptions[5]]}
                            
                          options={options}
                         />
                         {/* {owner.map((u,i)=>
                            <li>
                              <a className href="#">
                                <span className="relative align-middle avatar-circle d-inline-block me-1">
                                  <img className="img-fluid" src="image/graph.png" />
                                </span>
                               {u.owner_name}</a>
                            </li>
                            )}   */}
                          {/* </ul> */}
                        {/* </div> */}
                      </td>
                      <td>Apr 20th <br /> 2121</td>
                      <td className="align-middle">
                          <Rating rating={u.rating}/>
                      </td>
                   
                      <td className="align-middle">
                        <i className="mdi mdi-dots-vertical position-relative dropdown-toggle btn-no-image toogle-btn" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                         <button   onClick={()=>handleArchive(u.id)} className="dropdown-item"  ><i className="dripicons-archive me-1 " /> Archive to Talent Pool</button>
                          <a className="dropdown-item" href="#"><i className=" dripicons-tags me-1 " /> Add Tags</a>
                          <a className="dropdown-item" href="#"><i className=" dripicons-message me-1 " /> Share</a>
                          <a className="dropdown-item" href="#"  onClick={()=>handleDelete(u.id)}><i className="dripicons-trash me-1 " /> Delete</a> 
                        </div>
                      </td>
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
        </>
    )
}

export default CandidateTable;
