import React, { useState } from 'react'
import data from '../JobData.json'

function Jobcard() {
    const[job,setJob]=useState(data.JobPosted)
    return (
        <>
        {job.map((u,i)=>
          <div key={u.jobId} className="col-auto card-layout">
              <div className="card">
                <div className="card-body">
                  <a href="#" className="mb-3 d-block">
                    <h5 className="card-title text-center fw-normal">{u.JobTitle}</h5>
                    <h4 className="card-title text-center ">{u.JobName}</h4>
                    <div className="chart-container text-center">
                      <div className="chart-score d-inline-block">
                        <div className="score-mid align-items-center d-flex">
                          <div className="row mx-0 mt-n1 ">
                            <h2 className="fw-light m-0 w-100 ">{u.Total}</h2>
                            <h4 className="fw-light m-0 w-100 ">Candidate</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="job-place d-table mt-2">
                      <div className="d-table-cell text-center small ">
                        <i className="dripicons-location" />&nbsp;
                      {u.Location}
                      </div>
                    </div>
                  </a>
                  <div className="col-block-footer">
                    <div className="job-status small float-start" style={{paddingTop: '0.2rem'}}>
                      <i className="dripicons-web" />
                      &nbsp;
                      Published
                    </div>
                    <a href="/hire" className="btn btn-default btn-sm float-end p-0">Details&nbsp;<i className="dripicons-chevron-right" /></a>
                  </div>
                </div>
              </div>
            </div>   
            )}   
        </>
    )
}

export default Jobcard
