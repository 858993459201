import React from 'react'

function HiringWork() {
    return (
        <>
          <div className="row m-0 px-2">
          <div className="col-md-12 my-2 ">
            <div className="p-3 bg-white rounded ">
              <h4> Hiring Workflow </h4>
              <div className="row mt-3 ">
                <div className="col-md-4 ">
                  <div className="row ">
                    <div className="col-md-3 ">
                      <svg id="interview-scorecard " viewBox="0 0 60 60 ">
                        <g id="Symbols ">
                          <g id="intcard ">
                            <g id="Group-17-Copy-6 ">
                              <g id="Page-1 ">
                                <polygon id="Fill-1 " style={{fill: 'none'}} points="0,60 60,60 60,0 0,0 " />
                                <g id="Group-20 " transform="translate(11.000000, 9.000000) ">
                                  <g id="Group-19 ">
                                    <path style={{fill: '#FF5969'}} d="M10.7,36.8H18V42h-7.3V36.8z M20,36.8V42h7.3v-5.2H20z " />
                                    <path style={{fill: '#F0F0F0'}} d="M0.8,1H18v6.2H0.8V1z M0.8,18.6H18v-6.2H0.8V18.6z M0.8,30.6H18v-6.2H0.8V30.6z M0.8,42h7.3v-5.2H0.8V42z M29.9,36.8V42h7.3v-5.2H29.9z " />
                                  </g>
                                </g>
                              </g>
                              <g id="Group-2 " transform="translate(1.000000, 1.000000) ">
                              </g>
                            </g>
                            <path style={{fill: '#979797'}} d="M34.4,12.2h2.4l-1.9,1.3l0.7,2.2l-1.9-1.3l-1.9,1.3l0.7-2.2l-1.9-1.3h2.4l0.8-2.2L34.4,12.2z M33.6,21.4 l-0.8,2.2h-2.4l1.9,1.3l-0.7,2.2l1.9-1.3l1.9,1.3L34.8,25l1.9-1.3h-2.4L33.6,21.4z
                                          M34.4,35.6l-0.8-2.2l-0.8,2.2h-2.4l1.9,1.3 l-0.7,2.2l1.9-1.3l1.9,1.3l-0.7-2.2l1.9-1.3H34.4z M39.9,10l-0.8,2.2h-2.4l1.9,1.3L38,15.7l1.9-1.3l1.9,1.3l-0.7-2.2l1.9-1.3h-2.4 L39.9,10z M41.1,25l1.9-1.3h-2.4l-0.8-2.2l-0.8,2.2h-2.4l1.9,1.3L38,27.1l1.9-1.3l1.9,1.3L41.1,25z
                                          M46.1,10l-0.8,2.2H43l1.9,1.3 l-0.7,2.2l1.9-1.3l1.9,1.3l-0.7-2.2l1.9-1.3h-2.4L46.1,10z M54.7,3.8v52.4c0,1.9-1.6,3.5-3.5,3.5H8.8c-1.9,0-3.5-1.6-3.5-3.5V3.8 c0-1.9,1.6-3.5,3.5-3.5h42.4C53.1,0.3,54.7,1.9,54.7,3.8z M53.3,3.8c0-1.2-0.9-2.1-2.1-2.1H8.8c-1.2,0-2.1,0.9-2.1,2.1v52.4
                                          c0,1.2,0.9,2.1,2.1,2.1h42.4c1.2,0,2.1-0.9,2.1-2.1V3.8z M11.8,45.8h7.3V51h-7.3V45.8z M13.2,49.6h4.5v-2.4h-4.5V49.6z M40.9,45.8 h7.3V51h-7.3V45.8z M42.3,49.6h4.5v-2.4h-4.5V49.6z " />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="col-md-9 ">
                      <p className="text-muted "> Set up custom workflows to automate your repetitive and manual tasks </p>
                      <button className="btn btn-primary shadow rounded-pill px-3 "> Manage Autopilot Workflows </button>
                    </div>
                  </div>
                </div>
                {/* col-md-4 ends */}
                <div className="col-md-4 ">
                  <div className="row ">
                    <div className="col-md-3 ">
                      <svg id="interview-process " viewBox="0 0 60 60 ">
                        <g id="Symbols ">
                          <g id="intproc ">
                            <g id="Group-17-Copy-5 ">
                              <g id="Page-1 ">
                                <polygon id="Fill-1 " style={{fill: 'none'}} points="0,60 60,60 60,0 0,0 " />
                              </g>
                              <g id="Group-6 " transform="translate(5.000000, 0.000000) ">
                                <g id="Group-2 " transform="translate(1.000000, 1.000000) ">
                                </g>
                                <g id="Group-18 " transform="translate(15.000000, 9.000000) ">
                                  <rect id="Rectangle-2-Copy " x="0.8 " y="19.8 " style={{fill: '#F0F0F0'}} width="19.2 " height="4.8 " />
                                  <rect id="Rectangle-2-Copy-4 " x="0.8 " y="11.5 " style={{fill: '#F0F0F0'}} width="19.2 " height="4.8 " />
                                  <rect id="Rectangle-2-Copy-2 " x="0.8 " y="28.1 " style={{fill: '#F0F0F0'}} width="19.2 " height="4.8 " />
                                  <path style={{fill: '#FF5969'}} d="M9.7,35.5c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4S11.5,35.5,9.7,35.5z M9.7,40.8 c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C11.6,39.9,10.7,40.8,9.7,40.8z
                                                   M9.7,7.1c1.9,0,3.4-1.5,3.4-3.4 c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4C6.3,5.6,7.8,7.1,9.7,7.1z M9.7,1.8c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2 c-1.1,0-2-0.9-2-2C7.7,2.7,8.6,1.8,9.7,1.8z " />
                                  <path style={{fill: '#979797'}} d="M31.2-8.7h-42.4c-1.9,0-3.5,1.6-3.5,3.5v52.4c0,1.9,1.6,3.5,3.5,3.5h42.4c1.9,0,3.5-1.6,3.5-3.5V-5.2 C34.7-7.1,33.1-8.7,31.2-8.7z M33.3,47.2c0,1.2-0.9,2.1-2.1,2.1h-42.4c-1.2,0-2.1-0.9-2.1-2.1V-5.2c0-1.2,0.9-2.1,2.1-2.1h42.4
                                                   c1.2,0,2.1,0.9,2.1,2.1V47.2z M11.8,18.5l-1-1l-0.6,0.6v-1.8H8.9v2.1L8,17.5l-1,1l1.3,1.3H0.8v4.8H20v-4.8h-9.4L11.8,18.5z M18.6,23.2H2.2v-2h16.4V23.2z M10.2,10.9h0.1v-0.1l1.6-1.6l-1-1l-0.6,0.6V7.1H8.9v2L8,8.2l-1,1l2.3,2.3H0.8v4.8H20v-4.8H9.6
                                                   L10.2,10.9z M18.6,14.9H2.2v-2h16.4V14.9z M11.8,26.7l-1-1l-0.6,0.6v-1.7H8.9v2L8,25.7l-1,1l1.4,1.4H0.8v4.8h8.1v2.9h1.4v-2.9 H20v-4.8h-9.5L11.8,26.7z M18.6,31.5H2.2v-2h16.4V31.5z " />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="col-md-9 ">
                      <p className="text-muted "> Configure the different stages involved in selecting the right candidate for this job </p>
                      <button className="btn btn-primary shadow rounded-pill px-3 "> Manage Hiring Process </button>
                    </div>
                  </div>
                </div>
                {/* col-md-4 ends */}
                <div className="col-md-4 ">
                  <div className="row ">
                    <div className="col-md-3 ">
                      <svg id="workflow " viewBox="0 0 60 60 ">
                        <g id="Symbols ">
                          <g id="intproc ">
                            <g id="Group-17-Copy-5 ">
                              <g id="Page-1 ">
                                <polygon id="Fill-1 " style={{fill: 'none'}} points="0,60 60,60 60,0 0,0 " />
                              </g>
                              <g id="Group-2 " transform="translate(1.000000, 1.000000) ">
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <path style={{fill: '#F0F0F0'}} d="M47.4,30c0,3.3-2.7,5.9-5.9,5.9h-23c-3.3,0-5.9-2.7-5.9-5.9c0-3.3,2.6-5.9,5.9-5.9h23 C44.8,24.1,47.4,26.8,47.4,30z M18.5,18.2h23c3.2,0,5.9-2.6,5.9-5.9c0-3.2-2.6-5.9-5.9-5.9h-23c-3.3,0-5.9,2.7-5.9,5.9
                                       S15.2,18.2,18.5,18.2z M41.5,41.7h-23c-3.3,0-5.9,2.6-5.9,5.9c0,3.2,2.6,5.9,5.9,5.9h23c3.2,0,5.9-2.6,5.9-5.9 C47.4,44.4,44.8,41.7,41.5,41.7z " />
                          <path style={{fill: '#FF5969'}} d="M30.6,37.1l1,1l-2.3,2.3H29l-2.3-2.3l1-1l0.9,0.9v-2h1.1v1.9L30.6,37.1z M29.3,23l2.3-2.3l-1-1 l-0.9,0.9v-2h-1.1v2l-0.9-0.9l-1,1L29,23H29.3z " />
                          <path style={{fill: '#979797'}} d="M51.2,0.3H8.8c-1.9,0-3.5,1.6-3.5,3.5v52.4c0,1.9,1.6,3.5,3.5,3.5h42.4c1.9,0,3.5-1.6,3.5-3.5V3.8 C54.7,1.9,53.1,0.3,51.2,0.3z M53.3,56.2c0,1.2-0.9,2.1-2.1,2.1H8.8c-1.2,0-2.1-0.9-2.1-2.1V3.8c0-1.2,0.9-2.1,2.1-2.1h42.4
                                       c1.2,0,2.1,0.9,2.1,2.1V56.2z M31.7,26.4h1.2l-0.8,1l-1.6,2.3l-0.1,0.1v2.8l-1,1V30h-0.1l-1.7-2.6l-0.6-1h1.2H31.7z M25.7,11 l0.6-0.8l-1.5-1.4c-0.2-0.2-0.2-0.4,0-0.6s0.4-0.2,0.6,0l1.5,1.4l0.5-0.7l0.8,2.5L25.7,11z M32,9.8H33v6.1H32V9.8z
                                       M29.3,15.8 l1.4-5.9l1,0.2L30.3,16L29.3,15.8z M27.2,16.2l-0.9-0.5l3.1-5.3l0.9,0.5L27.2,16.2z M32.8,47.2l-4.4,4.9c-0.2,0.2-0.5,0-0.5-0.2 l1-3.6h-2.3c-0.2,0-0.4-0.3-0.2-0.5l4.4-4.9c0.2-0.2,0.5,0,0.5,0.3l-1.1,3.9l2.4-0.4C32.8,46.7,33,47,32.8,47.2z " />
                        </g>
                      </svg>
                    </div>
                    <div className="col-md-9 ">
                      <p className="text-muted "> Configure the different stages involved in selecting the right candidate for this job </p>
                      <button className="btn btn-primary shadow rounded-pill px-3 "> Manage Hiring Process </button>
                    </div>
                  </div>
                </div>
                {/* col-md-4 ends */}
              </div>
            </div>
          </div>
        </div>
        </>
    )
}

export default HiringWork
