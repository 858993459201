import React from 'react'


//import DashboardProject from './component/DashboardProject';
//import Login from './component/Login'
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom'

import Dashboard from './component/Dashboard';
import Recruite from './component/recruitment/Recruite';
import Conversation from './component/recruitment/Conversation';
import JobPosting from './component/JobPosting';
import Candidates from './component/Candidates';
import TalentPool from './component/TalentPool';
import Hire from './component/hiring/Hire'
import Settings from './component/settings/Settings';
import Location from './component/location/Location'
import Department from './component/department/Department';
import SubDepartment from './component/department/SubDepartment';
import Company from './component/company/Company';
import DeclineReason from './component/offer/DeclineReason';
import ApprovalReject from './component/offer/ApprovalReject';
import Employee from './component/employee/Employee';
import EditEmployee from './component/employee/EditEmployee';
import EmployeeList from './component/employee/EmployeeList';
function App() {
  return (
    <>
    <Router>
      <Switch>
        <Route path='/' exact component={Dashboard}></Route>
        <Route path="/hire" exact component={Hire}></Route>
        <Route path='/recruitment/recruite' exact component={Recruite}></Route>
        <Route path='/recruitment/conversation' exact component={Conversation}></Route>
        <Route path='/job/posting' exact component={JobPosting}></Route>
        <Route path='/candidates' exact component={Candidates}></Route>
        <Route path='/talent/pool' exact component={TalentPool}></Route>
        <Route path="/settings" exact component={Settings}></Route>
        <Route path='/locations' exact component={Location}></Route>
        <Route path='/departments' exact component={Department}></Route>
        <Route path='/sub-departments' exact component={SubDepartment}></Route> 
        <Route path='/company' exact component={Company}></Route>
        <Route path='/decline-reason' exact component={DeclineReason}></Route>
        <Route path='/approval-reject' exact component={ApprovalReject}></Route>
        <Route path='/employee' exact component={Employee}></Route>
        <Route path='/employee-edit/:id' exact component={EditEmployee}></Route>
        <Route path='/employee-list' exact component={EmployeeList}></Route>
      </Switch>
    </Router>
      {/* <Login /> */}
    </>
  );
}

export default App;
