import React, { useState } from 'react'
import departmentData from '../../Hiring.json'
function JobPosting() {
    const [department , setDepartment] = useState(departmentData.department)
    const [experience,setExperience] = useState(departmentData.experience)
    const [location,setLocation] = useState(departmentData.location)
    const [jobType,setJobType] = useState(departmentData.jobType)
    const [salary , setSalary] = useState({minSalary:'',maxSalary:''})
    const [currency , setCurrency] = useState(departmentData.currency)

    const salaryData = (e)=>{
        setSalary({...salary,[e.target.name]:e.target.value})
    }
    return (
        <>
            <div className="d-block w-100 bg-white p-3 rounded h-100">
              <h4> Job Posting Details </h4>
              <div className="users-select position-relative px-2 py-2 my-1 rounded  details-container">
                <label htmlFor className="text-body fw-normal"> Department </label>
                <h5 className="mb-0">
                  <div className="action-module">
                    <div className="spot-action-buttons vertical-align ">
                      <a href="#" className="users-select position-relative p-1 d-block" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="dripicons-pencil mdi-24px d-inline-block align-middle me-1" />
                      </a>
                      <div className="dropdown-menu p-0 " aria-labelledby="dropdownMenuButton1">
                        <div className="p-2">
                          <input className="ember-power-select-search-input form-control" aria-controls="ember-power-select-options-ember2026" placeholder="Search" type="search" />
                        </div>

                        <div className="p-2 overflow-scroll fix-dropdown" >
                          {department.map((dept,i)=>
                            <ul>
                            <li>
                              <a className href="#"> {dept}</a>
                            </li>
                          </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </h5>
              </div>
              <div className="users-select position-relative px-2 py-1 my-1 rounded details-container">
                <label htmlFor className="text-body fw-normal"> Experience </label>
                <h5 className="mb-0">
                  <div className="action-module">
                    <div className="spot-action-buttons vertical-align ">
                      <a href="#" className="users-select position-relative p-1 d-block" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="dripicons-pencil mdi-24px d-inline-block align-middle me-1" />
                      </a>
                      <div className="dropdown-menu p-0 " aria-labelledby="dropdownMenuButton2">
                        <div className="p-2 overflow-scroll fix-dropdown">
                            {experience.map((ex,i)=>
                            <ul>
                               <li>
                                 <a className href="#"> {ex} </a>
                               </li>
                             </ul>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </h5>
              </div>
              <div className="users-select position-relative px-2 py-1 my-1 rounded details-container">
                <label htmlFor className="text-body fw-normal"> Location </label>
                <h5 className="mb-0">
                  <div className="action-module">
                    <div className="spot-action-buttons vertical-align ">
                      <a href="#" className="users-select position-relative p-1 d-block" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="dripicons-pencil mdi-24px d-inline-block align-middle me-1" />
                      </a>
                      <div className="dropdown-menu p-0 " aria-labelledby="dropdownMenuButton3">
                        <div className="p-2 overflow-scroll fix-dropdown">
                            {location.map((lo,i)=>
                            <ul>
                              <li>
                                <a className href="#"> {lo}  </a>
                              </li>
                            </ul>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </h5>
              </div>
              <div className="row my-2 ms-2 ">
                <div className="form-check position-relative details-container">
                  <input type="checkbox" className="form-check-input" id="remote_job" />
                  <label className="form-check-label font-weight-bold m-0 p-0" htmlFor="remote_job">Mark as Remote Job</label>
                  <div id="tooltip" className="position-relative ms-5 ps-5">
                    <button type="button" className="btn" data-bs-container="#tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title=" Job title is marked as remote. As Job-boards mandate a location, default branch is chosen as location. You can add preferred locations to change it.">
                      <i className="dripicons-information position-absolute top-0 me-0 font-13" /> 
                    </button>
                  </div>
                </div>
              </div>
              <div className="users-select position-relative px-2 py-1 mt-2 mb-1 rounded details-container">
                <label htmlFor className="text-body fw-normal">  Job Type </label>
                <h5 className="mb-0">
                  <div className="action-module">
                    <div className="spot-action-buttons vertical-align ">
                      <a href="#" className="users-select position-relative p-1 d-block" id="dropdownMenuButton4" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="dripicons-pencil mdi-24px d-inline-block align-middle me-1" />
                      </a>
                      <div className="dropdown-menu p-0" aria-labelledby="dropdownMenuButton4">
                        <div className="p-2">
                          <input className="ember-power-select-search-input form-control" aria-controls="ember-power-select-options-ember2026" placeholder="Search" type="search" />
                        </div>
                        <div className="p-2 overflow-scroll fix-dropdown">
                            {jobType.map((jt,i)=>
                            <ul>
                               <li>
                                 <a className href="#">{jt}</a>
                               </li>
                            </ul>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </h5>
              </div>
              <div className="users-select position-relative px-2 py-1 my-1 rounded details-container">
                <label htmlFor className="text-body fw-normal"> Salary details </label>
               
                <h5 className="mb-0">
                  <div className="action-module">
                    <div className="spot-action-buttons vertical-align ">
                      <a href="#" className="users-select position-relative p-1 d-block" id="dropdownMenuButton5" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="dripicons-pencil mdi-24px d-inline-block align-middle me-1" />
                      </a>
                      <div className="dropdown-menu p-0 " aria-labelledby="dropdownMenuButton5">
                        <div className="p-2">
                          <a className="dropdown-header ps-1 fw-bold mb-1" href="#"> Salary Details </a>
                          <form action>
                            <div className="row g-2">
                              <div className="mb-3 col-md-5 text-center">
                                <label htmlFor className="form-label">Min Salary</label>
                                <input type="text" className="form-control" name="minSalary" value={salary.minSalary} onChange={(e)=>salaryData(e)}  />
                              </div>
                              <div className="col-md-2 m-auto text-center">
                                to
                              </div>
                              <div className="mb-3 col-md-5 text-center">
                                <label htmlFor className="form-label">Max Salary</label>
                                <input type="text" className="form-control"  name="maxSalary" value={salary.maxSalary} onChange={(e)=>salaryData(e)} />
                              </div>
                            </div>
                            <div className="mb-3">
                              <label htmlFor className="form-label"> Currency </label>
                              <select id className="form-select" value={currency} onChange={(e)=>setCurrency(e.target.value)} >
                                {currency.map((cur,i)=>
                                 <option key={i}>{cur}</option>
                                )}
                              </select>
                            </div>
                            <div className="text-end mb-1">
                              <button type="reset" className="btn"> Cancel </button>
                              <button type="submit" className="btn btn-primary"> Save </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </h5>
              </div>
              <div className="px-2 position-relative details-container">
                <div className="form-check form-switch">
                  <input type="checkbox" className="form-check-input" id="customSwitch1" />
                  <label className="form-check-label mx-0 px-1 font-14" htmlFor="customSwitch1">Allow employees to apply</label>
                </div>
              </div>
            </div>
            <div className="align-content-end text-center bottom-text">
              <span className="text-muted text-center"> Created on : April 13th 2021 , at 3.47 pm   (10 days ago) </span>
            </div>
        </>
    )
}

export default JobPosting
