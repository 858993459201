import React from 'react'
import { NavLink } from 'react-router-dom'

function RecruitAndTalent() {
    return (
        <>
   <div>
  <div className="mt-3">
    <h5>Productivity</h5>
    <div className="email-menu-list labels-list">
      <a href="email-notifications.html" className="text-info font-600">Email Notifications</a>
      <a href="canned-responses.html" className="text-info font-600">Canned Responses</a>
    </div>
  </div>
  <hr />
  <div className="mt-3">
    <h5>Roles &amp; Privileges</h5>
    <div className="email-menu-list labels-list">
      <a href="manage-roles.html" className="text-info font-600">Manage Roles</a>
    </div>
  </div>
  <hr />
  <div className="mt-3">
    <h5>Account</h5>
    <div className="email-menu-list labels-list">
      <a href="account.html" className="text-info font-600">Account</a>
      <a href="billing.html" className="text-info font-600">Plans &amp; Billing</a>
      <a href="security.html" className="text-info font-600">Security</a>
    </div>
  </div>
  <hr />
  <div className="mt-3">
    <h5>Integrate with other Apps </h5>
    <div className="email-menu-list labels-list">
      <a href="integrations.html" className="text-info font-600">Integration</a>
      <a href="apps.html" className="text-info font-600">Apps</a>
    </div>
  </div>
  <hr />
  <div className="mt-2">
    <h5>Job Setup </h5>
    <div className="email-menu-list labels-list">
      <a href="javascript: void(0);" className="text-info font-600">Email</a>
      <a href="javascript: void(0);" className="text-info font-600">Tags</a>
      <a href="javascript: void(0);" className="text-info font-600">Source</a>
      <a href="javascript: void(0);" className="text-info font-600">Vendors</a>
      <a href="javascript: void(0);" className="text-info font-600">Candidate Reject reasons</a>
    </div>
  </div>
  <hr />
  <div className="mt-3">
    <h5>Job Publishing </h5>
    <div className="email-menu-list labels-list">
      <a href="career-site.html" className="text-info font-600">Career Site</a>
      <a href="job-fields.html" className="text-info font-600">Job Fields</a>
      <a href="job-embeds.html" className="text-info font-600">Job Embeds</a>
      <a href="social.html" className="text-info font-600">Social</a>
      <a href="listener.html" className="text-info font-600">Listener</a>
    </div>
  </div>
  <hr />
  <div className="mt-3">
    <h5>Offer </h5>
    <div className="email-menu-list labels-list">
      <a href="templates.html" className="text-info font-600">Templates</a>
      <a href="fields.html" className="text-info font-600">Fields</a>
      <NavLink to="/decline-reason" className="text-info font-600">Decline Reasons</NavLink>
      <a href="approval-rules.html" className="text-info font-600">Approval Rules</a>
      <NavLink to="/approval-reject" className="text-info font-600">Approval Reject Reasons</NavLink>
    </div>
  </div>

</div>

        </>
    )
}

export default RecruitAndTalent
