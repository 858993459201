import React from 'react'

function JobDesc() {
    return (
        <>
         <div className="d-block bg-white h-100 rounded p-4">
              <div className>
                <h4 className="mt-0">Job Title</h4>
                <h5 className="mb-3 fw-normal detail-title">Dot Net Developer </h5>
              </div>
              <div className="edit-field">
                <div className="action-buttons">
                  <div className="spot-action-buttons align-items-center">
                    <a aria-label="Edit" className="p-1">
                      <i className="dripicons-document-edit" />
                    </a>
                  </div>
                </div>
                <h4> Job Description </h4>
                <p> We are looking for an experienced and ambitious .Net Developer to join our team. As a .NET Developer, you will be building various enterprise applications using the latest Microsoft technology stack. If you love solving
                  problems, believe in collaboration, and are committed to quality product development - this role is perfect for you. <br />
                </p>
                <ul style={{listStyleType: 'bullet'}}>
                  <p> Job Responsibilities </p>
                  <li>Understanding requirements from functional specifications received in requirement document </li>
                  <li>Performing analysis with design &amp; development of the project and creating technical specifications based on functional requirements </li>
                  <li> Designing, building, and maintaining efficient, reusable, and reliable code </li>
                  <li>Creating unit tests according to the test plans </li>
                  <li> Ensuring the best possible performance, quality, and responsiveness of applications </li>
                  <li> Identifying bottlenecks and devising solutions to mitigate and address these issues </li>
                  <li>Maintaining code quality, organization, and automatization </li>
                  <li>Hands-on experience with ASP.NET, .NET Framework &amp; MVC </li>
                  <li> Strong understanding of object-oriented programming </li>
                  <li> Familiar with various design and architectural patterns</li>
                  <li> Knack for writing clean, readable, and easily maintainable code </li>
                  <li> Strong proficiency with Microsoft SQL Server and writing SQL Queries </li>
                  <li> Web development technologies such as HTML 5, CSS, JavaScript libraries such as jQuery, extJS etc.</li>
                  <li> Working on Angular is a plus Understanding Design pattern &amp; Microservices is a big plus</li>
                </ul>
                <a href="#" className="text-info"> View More </a>
              </div>
              <div className="edit-field">
                <div className="action-buttons">
                  <div className="spot-action-buttons align-items-center">
                    <a aria-label="Edit" className="p-1">
                      <i className="dripicons-document-edit" />
                    </a>
                  </div>
                </div>
                <h4> Skills </h4>
                <p> DOT NET, MVC FRAMEWORKS, ASP.NET </p>
              </div>
            </div>
        </>
    )
}

export default JobDesc
