import React,{useState} from 'react'

function Table({data}) {
    const[bool,setBool]=useState(false)
    const [dept,setDept]=useState(data)
    const[newDept,setNewDept]=useState('')
    const[department,setDepartMent]=useState('')
  
    const handleDelete=()=>{
     
        let d=dept.filter(i=>i!==department);
        setDept(d)
        console.log(d)
    }

const handleData=(u)=>{
    console.log(u)
setDepartMent(u)
}
const addDepartment=()=>{
    setBool(true)
  }
  const clickRight=()=>{
    setBool(false)
    if(newDept!=='')
    {
      dept.push(newDept)
    }
  
    // console.log(d)
    //dept.push(d)
  }
  const clickCancel=()=>{
    console.log('click')
    setBool(false)
  }
  
  const handleChange=(e)=>
  {
   // console.log(e.target.value)
    setNewDept(e.target.value)
  }

    return (
        <>
              <div className="col-lg-9">{/*company form*/}
        <div className="ms-lg-4 mt-3 p-4 bg-white">
          <button className="btn btn-outline-secondary mb-3" onClick={addDepartment}>Add new</button>
          
          <ul className="list-group py-2">
          {bool?
                  <li className="list-group-item list-group-item-action position-relative">
                    <div className="show-btns">
                      <div className="row">
                        <div className="col-lg-12">
                          <input type="text" className="border-0 w-100 bg-transparent" onChange={handleChange}/>
                        </div>
                      </div>
                      <div className="hover-btns position-absolute" id="tooltip-container2">
                        <span>
                          <i onClick={clickRight} className="uil uil-check align-middle me-1 align-middle text-success fs-3 " />
                          <i onClick={clickCancel} className="uil uil-times text-danger align-middle fs-3" />
                        </span>
                      </div>
                    </div>
                  </li>:''}
              {dept.map((u,i)=>
            <li className="list-group-item list-group-item-action position-relative">
              <div className="show-btns">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="mb-0">{u}</p>
                  </div>
                </div>
                <div className="hover-btns position-absolute" id="tooltip-container2">
                  <span>
                    <i className=" dripicons-document-edit align-middle me-2" /> 
                    <i onClick={()=>handleData(u)} className=" mdi mdi-trash-can-outline fs-4 me-2" data-bs-toggle="modal" data-bs-target="#delete-department" />
                  </span>
                </div>
              </div>
            </li>
  )}
            
          </ul>
        </div>
      </div>

      <div className="modal fade mt-4 py-4 show" id="delete-department" tabIndex={-1} aria-labelledby="standard-modalLabel" role="dialog" aria-modal="true">
  <div className="modal-dialog standard-modal">
    <div className="modal-content">
      <div className="modal-body px-3">
        <div className="d-flex align-items-center">
          <i className="uil uil-exclamation-octagon text-warning me-2 fs-2" />
          <p className="fw-normal mb-0 fs-4 semi-bold"> Administration might be associated to certain Employees. After deletion, this cannot be associated with any Employee in the future.
            Do you still wish to delete this Department?
          </p>
        </div>
        <div className="alert alert-warning text-info mt-4" role="alert">
          <b>Warning</b>: This department will not be available for use anymore.
        </div>
      </div>
      <div className="modal-footer">
        <div className="ms-auto">
          <ul className="list-unstyled email-bulk-ul ">
            <li className="list-inline-item">
              <button type="button" className="btn border-0 btn-outline-light text-info" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
            </li>
            <li className="list-inline-item text-center rounded email-bulk-li">
              <button type="button" onClick={handleDelete} className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Delete</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
        </>
    )
}

export default Table
