import React from 'react'
import Navigation from '../../common/Navigation'
import Topbar from '../../common/Topbar'
import {Link,NavLink} from 'react-router-dom'
import CandidateNav from '../../common/CandidateNav'
import Jobcard from '../../common/Jobcard'
import JobFilter from '../../common/JobFilter'
function Recruite() {
    return (
        <>
          <div className="wrapper">
  <div id="leftside" >
      <Navigation/>
  </div>
  <div className="content-page">
    <div className="content">
      <div className="navbar-custom">
        <div id="top-bar" >
            <Topbar/>
            </div>
       <CandidateNav/>
        <Link to="/job/posting" className="btn btn-outline-secondary bg-white text-dark apply-btn">Add Job Posting</Link>
      </div>
      <div className="page-title-box ">
        <div className="tab-pane show active" id="dash">
          <div className="row m-0 pt-2 pb-3">
          <JobFilter/>
           <Jobcard/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    )
}

export default Recruite
